import { zodResolver } from '@hookform/resolvers/zod';
import type { FieldValues } from 'react-hook-form';
import type { ZodRawShape } from 'zod';
import { z } from 'zod';
import type { FieldDefinition } from '~/common/components/Forms/FormElements';

export type ServerFieldErrors = Record<string, string>;
export type ZodFormActionData = { errors: ServerFieldErrors };

export function getSchemaForServer(
    fieldDefinitions: Array<Pick<FieldDefinition, 'validationSchema' | 'name'>>
) {
    const zodSchemaForResolver: ZodRawShape = {};

    for (let i = 0; i < fieldDefinitions.length; i++) {
        const field = fieldDefinitions[i];
        if (field.validationSchema)
            zodSchemaForResolver[field.name] = field.validationSchema;
    }

    return z.object(zodSchemaForResolver);
}

export function getDefaultValuesAndResolverForClient(
    fieldDefinitions: Array<FieldDefinition>
) {
    const defaultValues: FieldValues = {};
    const zodSchemaForResolver: ZodRawShape = {};

    for (let i = 0; i < fieldDefinitions.length; i++) {
        const field = fieldDefinitions[i];
        defaultValues[field.name] = field.defaultValue;
        if (field.validationSchema)
            zodSchemaForResolver[field.name] = field.validationSchema;
    }

    const resolver = zodResolver(z.object(zodSchemaForResolver));
    return { defaultValues, resolver };
}

export function getResolverFromSchema<T>(schema: z.ZodType<T>) {
    return zodResolver(schema);
}

// This function will turn a Zod error from server validation
// into a simple object for errors that we would get from
// React Hook Form on the client. This is an example
// of the data model for a ZodError:
//
// errors: ZodError: [
//   {
//     "code": "too_small",
//     "minimum": 100000,
//     "type": "string",
//     "inclusive": true,
//     "exact": false,
//     "message": "Required",
//     "path": [
//         "name"
//     ]
//   }
// ]
//
// TODO: this only supports non-nested objects right now,
// this should handle longer paths.
export function getErrorsFromZodIssues(errors: z.ZodIssue[]) {
    let simplifiedErrors: ServerFieldErrors = {};

    for (const error of errors) {
        simplifiedErrors[error.path[0]] = error.message;
    }

    return simplifiedErrors;
}

export function getErrorsFromZodError(error: z.ZodError) {
    const formErrors = error.errors.reduce((prev, curr) => {
        const key = curr.path.join('.');
        const message = curr.message || 'Invalid value.';
        return { ...prev, [key]: message };
    }, {});

    return formErrors;
}
